import React from "react";
import { useEffect } from "react";
import i18n from "../../i18n";

const FilterComponent = ({ filterText, onFilter, onClear, changeBarcodeInput, barcodeScan, refreshData, showConsume }) => {

    useEffect(() => {
        let UPC = '';
        let reading = false;
        // console.log("scan init")
        const handleScan = function (e) {
            // console.log(e)
            const textInput = e.key || String.fromCharCode(e.keyCode);
            const targetName = e.target.localName;
            // let UPC = '';
            if (textInput && textInput.length === 1 && targetName !== 'input'){
                UPC = UPC+textInput;

                // if (UPC.length >= 6) {
                    // console.log('barcode scanned:  ', UPC);
                // } 
            }

            if (!reading) {
                reading = true;
                setTimeout(() => {
                    //if (UPC.length > 3) {
                    changeBarcodeInput(UPC)
                   //}
                    
                    
                    reading = false;
                    barcodeScan(UPC)
                    UPC = "";
                    changeBarcodeInput("")
                }, 150);
            }
        }
        // document.addEventListener("textInput", handleScan)
        document.addEventListener("keypress", handleScan);

        return () => {
            // console.log("scan destroy")
            document.removeEventListener("keypress", handleScan)
            // document.removeEventListener("textInput", handleScan)
        }
    }, [])

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        }}>
            {/* <input
                id='barcode'
                type='text'
                placeholder='Barcode'
                value={barcodeInput}
                onChange={(e) => changeBarcodeInput(e.target.value)}
            >
            </input> */}
            <div>
                <button className="btn btn-success" onClick={refreshData}>{i18n.t("Ανανέωση")}</button>
                <button className="btn btn-primary mx-2" onClick={showConsume}>{i18n.t("Αναλώσεις")}</button>
            </div>
            
            <div>
                <input
                    id='search'
                    type='text'
                    placeholder={i18n.t("Αναζήτηση")}
                    value={filterText}
                    onChange={onFilter}
                >
                </input>
                <button className='search-button' onClick={onClear}>✕</button>
            </div>
        </div>
    )
};

export default FilterComponent