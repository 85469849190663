import Flatpickr from "react-flatpickr";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import "flatpickr/dist/themes/material_blue.css";
import './Production.css';
import storage from '../../storage';
import request from '../../request';
import { useState, useEffect, useCallback } from 'react';
import { useParams } from "react-router-dom";
import moment from 'moment';
import DataTable from "react-data-table-component";
import React, { useMemo } from "react"; //Pass a “create” function and an array of dependencies. useMemo will only recompute the memoized value when one of the dependencies has changed. This optimization helps to avoid expensive calculations on every render.
import FilterComponent from "./Filter";
import { Edit } from 'react-feather';
import Table from 'react-bootstrap/Table';
import history from "../../history";
import Swal from 'sweetalert2';
import i18n from "../../i18n";
import Loader from "../loader/Loader";


const ExpandedComponent = ({ data }) => {
    const [imgData, setImageData] = useState("") 
    const [imgType, setImageType] = useState('')
    // CONST [imgType, setImageType] = useState('')
    const [show, setShow] = useState(false);
    const [showbom, setShowBom] = useState(false);
    const handleClose = () => setShow(false);
    const handleCloseBom = () => setShowBom(false);
    const handleShow = () => setShow(true);
    const [showLoading, setShowLoading] = useState(false)
    const [bom, setItemBom] = useState([])
    const [img, setImg] = useState('')
    // img.onload = () => {
    //     img.onload = () => {}
    //     img.src = img.src
    // }

    const showImage = (id) => {
        if(typeof id !='undefined') {
            setShowLoading(true)
            storage.set('order_id', id);
            request.get(`imageItem/${storage.order_id}`).then(
                response => {
                    setImageData(response.data.base64_img);
                    setImageType(response.data.ext);
                    if ((response.data.ext == 'jpg' || response.data.ext == 'jpeg' || response.data.ext =='png' || response.data.ext == 'gif' || response.data.ext == 'bmp')) {
                        setImg(`data:image/${response.data.ext};charset=utf-8;base64,${response.data.base64_img}`)
                    }
                    //  setImageType(response.data.ext);
                   // console.log(response.data.base64_img)
                   setShowLoading(false)
                }
            ).catch(
                r => {
                    setShowLoading(false)
                }
            )
            setShow(true);
        }
       
    }

    const getBom = (item_id) => {
        setShowBom(true)
        request.get(`bom/${item_id}`).then(
            response => {
                if(response.data && response.data != 'undefined' && response.data !=null) {
                    // console.log(response.data);
                    const d = Object.entries(response.data).map( item => {
                        return (
                            item[1]
                        )
                    })
                    setItemBom(d)
                }
            }
        )
    }
    const bom_columns = [
        {
            name:i18n.t("ΚΩΔ."),
            minWidth:'130px',
            maxWidth:'150px',
            selector: row => row.Code,
            sortable: true
        },
        {
            name:i18n.t("ΠΕΡΙΓΡΑΦΗ"),
            minWidth:'150px',
            selector: row => row.Descr,
            sortable: true
        },
        {
            name:i18n.t("ΠΟΣΟΤΗΤΑ"),
           width:'150px',
            selector: row => row.Quantity,
            sortable: true
        },
        {
            name:i18n.t("ΜΟΝΑΔΑ ΜΕΤΡ."),
            width:'150px',
            selector: row => row.unit,
            sortable: true
        },
        {
            name:i18n.t("ΦΥΡΑ %"),
            width:'115px',
            selector: row => row.waste,
            sortable: true,
            cell: (e) => parseFloat(e.waste * 100).toFixed(2)
        },
        {
            name:i18n.t("ΜΕΘΟΔ.ΑΝΑΛ"),
            width:'160px',
            selector: row => row.calctype,
            sortable: true
        },
    ]
    // const stBase64 = (img) => {
    //         img.onload = () => {
    //         console.log(
    //         `[Onload] - VolleyBall ball image is of dimension ${volleyBall.width} x ${volleyBall.height}`
    //         )
    //     }
    // }
    useEffect(() => {
        if (img !== '') {
            setTimeout(() => {
                document.querySelector('.imgmodal').src = img
            }, 1)
        }
    }, [img])

    return (
        <div className='extra-data-tbl'>
            <div className='d-flex justify-content-around bordbotm'>
                <div className="w-25 pd-5">{i18n.t("ΟΙΚΟΓΕΝΕΙΑ ΤΟΥ ΕΙΔΟΥΣ")}</div>
                <div className="w-75 pd-5">{data.fdescr}</div>
            </div>
            <div className='d-flex justify-content-around bordbotm'>
                <div className="w-25 pd-5">{i18n.t("ΡΟΗ ΕΠΕΞΕΡΓΑΣΙΑΣ ΕΙΔΟΥΣ")}</div>
                <div className="w-75 pd-5">{data.pdescr}</div>
            </div>
            <div className='d-flex justify-content-around bordbotm'>
                <div className="w-25 pd-5">{i18n.t("ΜΗΚΟΣ ΕΙΔΟΥΣ")}</div>
                <div className="w-75 pd-5">{data.length}</div>
            </div>
            <div className='d-flex justify-content-around bordbotm'>
                <div className="w-25 pd-5">{i18n.t("ΠΑΧΟΣ ΕΙΔΟΥΣ")}</div>
                <div className="w-75 pd-5">{data.width}</div>
            </div>
            <div className='d-flex justify-content-around bordbotm'>
                <div className="w-25 pd-5">{i18n.t("ΥΨΟΣ ΕΙΔΟΥΣ")}</div>
                <div className="w-75 pd-5">{data.height}</div>
            </div>
            <div className='d-flex justify-content-around bordbotm'>
                <div className="w-25 pd-5">{i18n.t("ΣΕΙΡΙΑΚΟΣ ΑΡΙΘΜΟΣ")}</div>
                <div className="w-75 pd-5">{data.isserial == '1' ? i18n.t('Ναι') : i18n.t('Όχι')}</div>
            </div>
            <div className='d-flex justify-content-around bordbotm'>
                <div className="w-25 pd-5">{i18n.t("ΒΟΗΘΗΤΙΚΟΣ ΚΩΔΙΚΟΣ")}</div>
                <div className="w-75 pd-5">{data.seccode}</div>
            </div>
            <div className='d-flex justify-content-around bordbotm'>
                <div className="w-25 pd-5">{i18n.t("ΒΟΗΘΗΤΙΚΗ ΠΕΡΙΓΡΑΦΗ")}</div>
                <div className="w-75 pd-5">{data.secdescr}</div>
            </div>
            <div className='d-flex justify-content-around bordbotm'>
                <div className="w-25 pd-5">{i18n.t("ΠΡΟΤΥΠΟΣ ΧΡΟΝΟΣ")}</div>
                <div className="w-75 pd-5">{data.unittime}</div>
            </div>
            <div className='d-flex justify-content-around bordbotm'>
                <div className="w-25 pd-5">{i18n.t("ΑΡΧΕΙΟ")}</div>
                <div className="w-75 pd-5">{data.hasimage == 1 ? <button className="btn btn-primary" onClick={ () =>  showImage(data.ItemID)}>ΠΡΟΒΟΛΗ</button> : '-'}</div>
            </div>
            <div className='d-flex justify-content-around bordbotm'>
                <div className="w-25 pd-5">{i18n.t("ΣΥΝΤΑΓΗ")}</div>
                <div className="w-75 pd-5"><button className="btn btn-primary" onClick={ () =>  getBom(data.ItemID)}>ΠΡΟΒΟΛΗ</button></div>
            </div>
            {
            // gia test
            }
            <Modal show={showbom} onHide={handleCloseBom} dialogClassName="modalconsumes modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>{i18n.t("ΣΥΝΤΑΓΗ")}</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <DataTable
                            tile={i18n.t("ΣΥΝΤΑΓΗ")}
                            columns= {bom_columns}
                            data= {bom}
                            noDataComponent={i18n.t("Δεν υπάρχουν εγγραφές")}
                            ></DataTable>
                        </div>
                    </Modal.Body>
            </Modal>
            <Modal size="xl" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{i18n.t("Αρχείο")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {(imgType == 'jpg' || imgType == 'jpeg' || imgType =='png') && (
                   
                    <img className='imgmodal' src={img} style={{width: '100%', minHeight: '80vh'}} />
                )
                }
                {imgType == 'pdf' && (
                    <embed style={{width: '100%', minHeight: '80vh'}} src={`data:application/pdf;base64,${imgData}`} />
                )}
                {imgType == 'gif' && (
                    <img className='imgmodal' src={img}/>
                )
                }
                {imgType == 'bmp' && (
                    <img className='imgmodal' src={img}/>
                )
                }
                    </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
            <Loader show={showLoading}/>
            
        </div>
    )
    
}



function Production(props) {
    const params = useParams();

    const [currentRow, setCurrentRow] = useState(null);
    const [showLoading, setShowLoading] = useState(false)
    const [machineList, setMachineList] = useState([])
    const [selectedmachine, setSelectMachine] = useState() //for object
    const [shiftList, setShiftList] = useState([])
    const [selectedShift, setSelectShift] = useState()
    const [filterText, setFilterText] = useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
    const [show, setShow] = useState(false);
    const [modalshow, setModalShow] = useState(false);
    const [modalconshow, setConModalShow] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [required, setRequired] = useState(false);
    const [error_message, setErrorMessage] = useState('')
    const [errors, setErrors] = useState({
        select_machine: false,
        select_shift: false
    })
    const [barcodeInput, setBarcodeInput] = useState('')
    const [ordersList, setOrdersList] = useState(null)
    const [processList, setProcessList] = useState([])
    const [comment, setComment] = useState('')
    const [orderID, setOrderID] = useState(params.orderID ? params.orderID : null)
    const [processID, setProcessID] = useState(params.processID ? params.processID : null)
    const [wastetypeList, setWasteTypeList] = useState([])
    const [addwasterow, setAddWasterow] = useState({
        wastetype: null,
        wastequa: '',
        comments: ''
    });
    const [toggleCleared, setToggleCleared] = useState(false)
    const [selectedWtype, setSelectWtype] = useState()
    const [data, setData] = useState([]);
    const [dataQtyHelper, setDataQtyHelper] = useState([]);
    const [scrap, setProductScrap] = useState([]);
    const [consume, setProductConsume] = useState([]);
    const [selectitem, setSelectItem] = useState();
    useEffect(() => {
        if (!params.machineID) {
            setIsShow(false)
        } else {
            setIsShow(true)
        }
    })
    const [selectedData, setSelectedData] = useState()
    /**Requests */
    const getMachines = () => {
        if (!storage.machines || typeof storage.machines == 'undefined' || storage.machines === null){
            request.get('/machines').then(response => {
                if (response.data) {
                    setMachineList(response.data)
                    if (params.machineID) {
                        const m = response.data.find(a => parseInt(a.MID) === parseInt(params.machineID))
                        if (typeof m !== 'undefined') {
                            
                            setSelectMachine({
                                label: `${m.MachineDescr}`,
                                value: m.MID
                            })
                        }
                    }
                }
            })
        } else {
            setMachineList(storage.machines)
            if (params.machineID) {
                const m = storage.machines.find(a => parseInt(a.MID) === parseInt(params.machineID))
                if (typeof m !== 'undefined') {
                    
                    setSelectMachine({
                        label: `${m.MachineDescr}`,
                        value: m.MID
                    })
                }
            }
        }
        
    }

    const getShifts = () => {
        if (!storage.shifts || typeof storage.shifts == 'undefined' || storage.shifts === null) {
            request.get('/shifts').then(response => {
                if (response.data) {
                    setShiftList(response.data)
                    if (params.shiftID) {
                        const m = response.data.find(a => parseInt(a.SHFTID) === parseInt(params.shiftID))
                        if (typeof m !== 'undefined') {
                            setSelectShift({
                                label: m.shiftdescr,
                                value: m.SHFTID
                            })
                        }
                    }
                }
            })
        } else {
            setShiftList(storage.shifts)
            if (params.shiftID) {
                const m = storage.shifts.find(a => parseInt(a.SHFTID) === parseInt(params.shiftID))
                if (typeof m !== 'undefined') {
                    setSelectShift({
                        label: m.shiftdescr,
                        value: m.SHFTID
                    })
                }
            }
        }
        
    }

    const getWastetypes = () => {
        if (!storage.waste_types || typeof storage.waste_types == 'undefined' || storage.waste_types === null) {
            request.get('/wastetypes').then(response => {
                if (response.data) {
                    setWasteTypeList(response.data);
                    storage.set('waste_types', response.data)
                }
            })
        } else {
            setWasteTypeList(storage.waste_types);
        }
        
    }

    const handleAction = (id) => {
        setShow(true);
        request.get(`/wastedetails/${storage.machine_id}/${storage.order_id}/${storage.process_id}/${id}`).then(
            response => {
                // console.log(response.data)
                const data = Object.entries(response.data).map ( item => {
                    return (
                        item[1]
                    )
                })
                setProductScrap(data)
                
            }
        )
        setSelectItem(id);
    }

    const handleCommands = (e) => { 
        if (typeof e.preventDefault === "function") { 
            e.preventDefault()
        }

        if (validateData()) {
            
            if (!showLoading)
                setShowLoading(true)
            // setIsShow(true)
            // storage.set('machine_id', selectedmachine.value);
            // storage.set('machine_name', selectedmachine.label);
            // storage.set('shift_id', selectedShift.value);
            // storage.set('shift_name',selectedShift.label);
            // console.log(selecteddate);

            request.get(`/machineorders/${storage.machine_id}`).then( 
                response => {
                    // console.log(response.data);
                    if (response.data && typeof response.data != 'undefined' && response.data !== null) {
                        const d = Object.entries(response.data).map( item => {
                            return (
                                item[1]
                            )
                        })
                       
                        
                        setOrdersList(d)
                        
                        if (!params.orderID || d.length == 0) {
                            setProcessList([])
                            setData([])
                            history.push(`/production/${selectedmachine.value}/${selectedShift.value}`)
    
                        }
                    } 
                    
                    if (showLoading)
                        setShowLoading(false)
                }
            )
        }
    }

    

    const getConsume = (id) => {
        setConModalShow(true)
        if(typeof id == 'undefined' || !id) {
            return
        }
        request.get(`consume/${id}`).then(
            response => {
                if(response.data && response.data != 'undefined' && response.data !=null) {
                    // console.log(response.data);
                    const d = Object.entries(response.data).map( item => {
                        return (
                            item[1]
                        )
                    })
                    setProductConsume(d)
                }
            }
        )
    }

    const getOrderDetails = (id) => {
        storage.set('process_id', id)
        setProcessID(id)
        setData([])
        if (!showLoading)
            setShowLoading(true)
        const comment = ordersList.find(o => o.POID == storage.order_id);
        if(comment.comments) {
            setComment(comment.comments)
        }else {
            setComment('')
        }
        request.get(`orderdetail/${storage.machine_id}/${storage.order_id}/${id}`).then(
            response => {
                if (response.data) {
                    const data = Object.entries(response.data).map( item => {
                        return (
                            {
                                ...item[1],
                                editable_quantity: parseFloat(item[1].qua).toFixed(2),
                                expanded: false
                            }
                            
                        )
                        
                    })

                    if (data.length == 0) {
                        request.get(`/machineorders/${storage.machine_id}`).then(
                            response => {
                                // console.log(response.data);
                                const d = Object.entries(response.data).map( item => {
                                    return (
                                        item[1]
                                    )
                                })
                                setOrdersList(d)
                                // if (!params.orderID || d.length == 0) {
                                //     history.push(`/production/${selectedmachine.value}/${selectedShift.value}`)
                                // }

                            }
                        )
                    } else {
                        
                        setData(data)
                        setDataQtyHelper(data)
                        // setIsShow(true)
                        history.push(`/production/${selectedmachine.value}/${selectedShift.value}/${storage.order_id}/${id}`)
                    }
                    
                } else {
                    // handleCommands({})
                }
                if (showLoading)
                    setShowLoading(false)
            }
               
        )
    }

    const onTableUpdate = (e) => {
        // console.log(e)
    }

    const addWastereq = () => {
        if(addwasterow.wastequa <= quantityItem(selectitem) && addwasterow.wastetype != null && addwasterow.wastequa > 0){
            setShowLoading(true)
            request.post('/addwaste',
            {
                
                machine_id: storage.machine_id,
                order_id: storage.order_id,
                process_id: storage.process_id,
                item_id: selectitem,
                wtype: addwasterow.wastetype.value,
                date: storage.date,
                shift: storage.shift_id,
                quantity: addwasterow.wastequa,
                comm: addwasterow.comments

            }
            ).then(
                response => {
                    const data = Object.entries(response.data.table_data).map ( item => {
                        return (
                            item[1]
                        )
                    })
                    if(response.status == '200') {
                        Swal.fire({
                            icon: 'success',
                            title: i18n.t("Η καταχώρηση ολοκληρώθηκε"),
                            showConfirmButton: false,
                            timer: 1500
                          })
                        setAddWasterow({
                            wastetype: null,
                            wastequa: '',
                            comments: ''
                        })
                        setSelectWtype(null)
                    }
                    getOrderDetails(storage.process_id)
                    setProductScrap(data)
                    setShowLoading(false)
            }).catch(r => {
                setShowLoading(false)
            })
            
        }else {
            Swal.fire({
                icon: 'error',
                title: i18n.t("Παρακαλούμε όπως εισάγετε επιτρεπτή ποσότητα"),
                // /showConfirmButton: false,
                confirmButtonColor: '#8bc34a',
                confirmButtonText: 'OK'
                // timer: 1500
              })
            // alert('error quantity');
        }
    }

    const handleInsert = () => {
        setShowLoading(true)
        request.post('/insertlines', 
        {
            machine_id: storage.machine_id,
            order_id: storage.order_id,
            process_id: storage.process_id,
            items: dataQtyHelper.filter(d => selectedData.filter(s => s.ItemID === d.ItemID).length > 0).map(a => {
                return {
                    id: a.ItemID,
                    qty: a.editable_quantity
                }
            }),
            date: storage.date,
            shift: storage.shift_id
            
        }).then( 
            response => {
                if (response.data.status === 200) {
                    // html: `<img src='${image_url}'/>`
                    var html = `<h4>${i18n.t("Λεπτομέρειες")}</h4>`;
                    Object.entries(response.data.items).forEach((val) => {
                        const val_text = val[1] == 1 ? i18n.t("Επιτυχία") : i18n.t("Μη επιτρεπτή ποσότητα");
                        const val_class = val[1] == 1 ? 'sucess-outcome' : 'error-outcome';
                        html += `<div class='${val_class}'>${dataQtyHelper.filter(s => s.ItemID === parseInt(val[0]))[0].Code}: ${val_text}</div>`;
                    })

                    Swal.fire({
                        icon: 'success',
                        title: i18n.t("Η καταχώρηση ολοκληρώθηκε"),
                        html: html,
                        text: text,
                        showConfirmButton: true,
                    })
                } else {
                    var text = i18n.t("Δεν μπορείτε να καταχωρήσετε ποσότητες για την συγκεκριμένη ημερομηνία");
                    if (response.data.status === 400) {
                        if (selectedData.length > 1) {
                            text = i18n.t("Οι ποσότητες που εισάγατε υπερβαίνουν το όριο");
                        } else {
                            text = i18n.t("Η ποσότητα που εισάγατε υπερβαίνει το όριο");
                        }
                        
                    }
                    Swal.fire({
                        icon: 'warning',
                        title: i18n.t("Η καταχώρηση απέτυχε"),
                        text: text,
                        showConfirmButton: true,
                    })
                }  
                getOrderDetails(storage.process_id)
                setToggleCleared(!toggleCleared);
                setShowLoading(false)
                
            }
        )
        
    };

    const contextActions = useMemo(() => {
		return (
			<Button key="insert" onClick={handleInsert} style={{ backgroundColor: 'green' }}>
				{i18n.t("Ενημέρωση Επιλεγμένων ειδών")}
			</Button>
		);
	}, [data, selectedData, dataQtyHelper]);
    const addProductreq = () => {
        
    }
    /*End of Requests */

    
    useEffect(() => { //san to document on load otan to  array einai keno
        getMachines();
        getShifts();
        getWastetypes();
    }, [])
    
    useEffect(() => { 
        if (params.shiftID && params.machineID) {
            handleCommands({})
        }
    }, [selectedmachine, selectedShift]) //exei times ara onchange

    
    const handleClose = () => setShow(false);

    const handleModalClose = () => setModalShow(false);

    const handleModalConClose = () => setConModalShow(false);

    function handleInputChange(a, id) {
        // e.target.quantity = e.target.value
        const temp = JSON.parse(JSON.stringify(dataQtyHelper))
        temp[temp.findIndex(t => t.ItemID === id)].editable_quantity = a.target.value //return first index of element than to element tou id na einai iso me to id pou tou dinoume sthn function
        setDataQtyHelper(temp)
        // setEditInput(a.target.value)
    }
    function handleInputChangeModal(a, id) {
        // e.target.quantity = e.target.value
        const temp = JSON.parse(JSON.stringify(scrap))
        temp[temp.findIndex(t => t.WAID === id)].quantity = a.target.value //return first index of element than to element tou id na einai iso me to id pou tou dinoume sthn function
        setProductScrap(temp)
        // setEditInput(a.target.value)
    }
    /*
    *Product-Scrap
    */

   const product_colums = [    
        {
            name:i18n.t("ΠΕΡΙΓΡΑΦΗ"),
            selector: row => row.Descr,
            sortable: true

        },
        {
            name:i18n.t("ΒΑΡΔΙΑ"),
            width: '120px',
            selector: row => row.shiftdescr,
        },
        {
            name: i18n.t("ΗΜΕΡΟΜΗΝΙΑ"),
            width: '140px',
            selector: row => row.wdate,
            sortable :true,
        },
        {
            name: i18n.t("ΠΟΣΟΤΗΤΑ"),
            width: '120px',
            selector: row => row.quantity,
            cell: (e) => <div className='cell-num'>{e.quantity}</div>
           
        },
        {
            name: i18n.t("ΣΧΟΛΙΑ"),
            selector: row => row.comments,
            
        },
    
   ]

   const consume_columns = [
    {
        name:i18n.t("ΚΩΔ. ΕΙΔΟΥΣ"),
        minWidth:'130px',
        maxWidth:'140px',
        selector: row => row.Code,
        sortable: true
    },
    {
        name:i18n.t("ΠΕΡΙΓΡΑΦΗ"),
        minWidth:'150px',
        selector: row => row.Descr,
        sortable: true

    },
    {
        name: i18n.t("ΠΟΣΟΤΗΤΑ"),
        width: '150px',
        selector: row => row.qua
       
    },
    {
        name: i18n.t("ΜΟΝΑΔΑ ΜΕΤΡ."),
        width: '110px',
        selector: row => row.unit,
       
    },
    {
        name: i18n.t("ΠΑΡΤΙΔΑ"),
        width:'150px',
        selector: row => row.lot,
       
    },
    {
        name: i18n.t("ΑΝΑΛΩΣΗ"),
        width: '150px',
        selector: row => row.served,
        cell: (e) => ( e.served == '1') ? i18n.t('Ναι') : i18n.t('Όχι')
    },
    {
        name: i18n.t("HM/ΑΝΑΛ."),
        width: '150px',
        selector: row => row.servedate ,
    },
   ]
    /*
    * Data-table 
    */
    const columns = [
        {
            name: i18n.t("ΚΩΔ."),
            selector: row => row.Code,
            sortable: true,
            minWidth:'130px',
            maxWidth:'160px'

        },
        {
            name: i18n.t("ΠΕΡΙΓΡΑΦΗ"),
            selector: row => row.Descr,
            sortable :true,
            filterable: true,
            minWidth:'150px',
        },
        // {
        //     name: i18n.t("ΜΕΓΙΣΤΗ ΠΟΣΟΤΗΤΑ ΠΑΡΑΓΩΓΗΣ"),
        //     selector: row => row.qua ,
        //     cell: (e) => <div className='cell-num'>{parseFloat(e.qua).toFixed(2)}</div> //xrhsimopoieitai gia reference
        // },
        {
            name: <div className='text-right'>{i18n.t("ΠΟΣΟΤΗΤΑ")}</div>,
            width: '130px',
            selector: row => row.qua ,
            cell: (e) => <input className='cell-num' type='number' value={dataQtyHelper.filter(d => d.ItemID === e.ItemID).length > 0 ? dataQtyHelper.filter(d => d.ItemID === e.ItemID)[0].editable_quantity : e.editable_quantity} onClick= {(event) => event.target.select()} onChange= { (a) => handleInputChange(a, e.ItemID)}/> //xrhsimopoieitai gia reference
        },
        {
            name: <div className='textcenter'>{i18n.t("ΣΚΑΡΤΑ")}</div>,
            width: '170px',
            padding: 0,
            selector: row => row.waste,
            cell: (e) => <div className="d-flex align-items-center justify-content-end cell-num"><span>{parseFloat(e.waste).toFixed(2)}</span><button className='btn' onClick={ () =>  handleAction(e.ItemID)}><Edit size='20' color='#3a3a3a' /></button></div>,
        },
        {
            name: i18n.t("ΜΟΝΑΔΑ ΜΕΤΡ."),
            width: '105px',
            selector: row => row.unit,
           
        },
        {
            name: i18n.t("ΧΡΟΝΟΣ"),
            selector: row => row.Dur,
            width: '100px',
            cell: (e) => <div className='cell-num'>{e.Dur}</div>
           
        },
    ]
    
    const handleChange2 = (state) => {
        // console.log('Selected Rows: ', state.selectedRows);
    }
    
      /* Filtering  table */

    const filteredItems = data.filter(item => {
        return (
            JSON.stringify(item).toLowerCase().indexOf(filterText.toLocaleLowerCase()) !== -1
        )
    }) 

    const changeBarcodeInput = (text) => {
        
        setBarcodeInput(text)
    }

    const barcodeScan = ((barcode) => {
        // console.log(barcode)
        if(barcode.length < 4) return;
        const items = filteredItems.filter(a => a.Code.toLowerCase() === barcode.toLowerCase())
        if (items.length > 0) {
            
            setShowLoading(true)
            request.post('/insertlines',
            {
                machine_id: storage.machine_id,
                order_id: storage.order_id,
                process_id: storage.process_id,
                items: items.map(a => {
                    return {
                        id: a.ItemID,
                        qty: 1
                    }
                }),
                date: storage.date,
                shift: storage.shift_id
            
            }).then(
                response => {
                    if (response.data.status === 200) {
                        // html: `<img src='${image_url}'/>`
                        var html = `<h4>${i18n.t("Λεπτομέρειες")}</h4>`;
                        Object.entries(response.data.items).forEach((val) => {
                            const val_text = val[1] == 1 ? i18n.t("Επιτυχία") : i18n.t("Μη επιτρεπτή ποσότητα");
                            html += `<div>${items.filter(s => s.ItemID === parseInt(val[0]))[0].Code}: ${val_text}</div>`;
                        })

                        Swal.fire({
                            icon: 'success',
                            title: i18n.t("Η καταχώρηση ολοκληρώθηκε"),
                            html: html,
                            text: text,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        var text = i18n.t("Δεν μπορείτε να καταχωρήσετε ποσότητες για την συγκεκριμένη ημερομηνία");
                        if (response.data.status === 400) {
                            if (items.length > 1) {
                                text = i18n.t("Οι ποσότητες που εισάγατε υπερβαίνουν το όριο");
                            } else {
                                text = i18n.t("Η ποσότητα που εισάγατε υπερβαίνει το όριο");
                            }
                            
                        }
                        Swal.fire({
                            icon: 'warning',
                            title: i18n.t("Η καταχώρηση απέτυχε"),
                            text: text,
                            showConfirmButton: true,
                        })
                    }
                    setToggleCleared(!toggleCleared);
                    getOrderDetails(storage.process_id)
                    setShowLoading(false)
                }
            )
        } else if (barcode.length > 0) {
            
            Swal.fire({
                icon: 'warning',
                title: i18n.t("Ο κωδικός δεν υπάρχει"),
                text: "",
                showConfirmButton: true,
            })
        }
    })


    const SubmitHeaderComponetButton = useCallback(state => {
        // const handleChange2 = (state) => {
        // console.log(state.selectedRows)
        const rows = Object.values(
            state.selectedRows.reduce((res, {ItemID, editable_quantity}) => {
            res[ItemID] ??= {ItemID, editable_quantity}
                return res
            }, {})
        )
        setSelectedData(rows)
           
        // }
    }, [selectedData])

    const manualRefresh = () => {
        setToggleCleared(true)
        handleCommands({})
    }

    const SearhHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };
      
        return (
            <FilterComponent
            onFilter={e => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
            changeBarcodeInput={changeBarcodeInput}  
            barcodeScan={barcodeScan} 
            refreshData={manualRefresh}
            showConsume = { () => getConsume(orderID)}
            />
        );
    }, [filterText, resetPaginationToggle, barcodeInput, filteredItems]); //poia afora 


    const showHandler = () => {
        handleAction();
    }
    
    function validateData() {
        const temp_errors = JSON.parse(JSON.stringify(errors))

        temp_errors.select_machine = !selectedmachine || selectedmachine.value == ''
        temp_errors.select_shift = !selectedShift || selectedShift.value == ''
        var valid = true
        Object.entries(temp_errors).map(item => { //object entries metatrepei ena object se array kai sthn sunexeia kai me to map ginetia h ruthmish
            if (item[1]) {
                valid = false
            }
            
        })
        setErrors(temp_errors)
        
        return valid
    }
    
    useEffect(() => {
        if (params.orderID && ordersList && ordersList.length > 0) {
            getProcesses(params.orderID)
        } else {
            setProcessList([])
            setData([])
            setDataQtyHelper([])
        }
    }, [ordersList])

    const validateOrderProcesses = async (order) => {
        return request.get(`/machineorders/${storage.machine_id}`).then(response => {
            if (response.data && typeof response.data != 'undefined' && response.data !== null) {
                const d = Object.entries(response.data).map( item => {
                    return (
                        item[1]
                    )
                })
                const findOrderProcess = d.find(elem => order.POID == elem.POID)

                if(typeof findOrderProcess != 'undefined') {
                    return findOrderProcess.processes
                }
                
            }  
        })
    }

    const getProcesses = async (id) => {
        const temp_orders = JSON.parse(JSON.stringify(ordersList));
        const findOrderProcess = temp_orders.find(elem => id == elem.POID);
        setOrderID(id)
        
        if(typeof findOrderProcess != 'undefined') {
            // let processes = findOrderProcess.processes
            let processes = await validateOrderProcesses(findOrderProcess)
            setProcessList(processes)
            storage.set('order_id', id)
            if (params.processID && processes.find(elem => params.processID == elem.PRID)) {
                if (params.processID == storage.process_id)
                    getOrderDetails(params.processID)
            } else {
                if (processes.length == 1) {
                    getOrderDetails(processes[0].PRID)
                }
                // history.push(`/production/${selectedmachine.value}/${selectedShift.value}/${id}`)
            }
            
        } else {
            setProcessList([])
            setData([])
            setDataQtyHelper([])
        }
    }
    /**
     * 
     * @param {*} id 
     * @returns details for commands such as descr
     */
    const getDetails = (id) => {

        const temp_orders = JSON.parse(JSON.stringify(ordersList));
        const details = temp_orders.find( elem => id == elem.POID);
        if(typeof id != 'undefined') {
            
            const details_command =  (
                
                <div className='container'>
                    <h2>{`Επιλεγμένη εντολή: ${details.POID}`}</h2>
                    <p>{details.descr}</p>
                </div>
                
            )
            // console.log(`${details.descr}`)
            return details_command;
        }
        
    }
    /**
         ordersList.map( (e, index) => {
                console.log(`aeeeeeeeeeeeeeeeeeeraaaaaaaaaaaaaaaaa${e.descr}`)
                return (
                    <div>
                        <h2>{e.descr}</h2>
                    </div>
                    
                )
                    
        })
        */

    const openAddModal = () => {
        setModalShow(true);
    }
    
    function handleAddWaste (a, index, fn = (e) => {}) {
        setAddWasterow({
            ...addwasterow, //san to expand
            [index]: a
        })

        fn(a)
    }

    

    function quantityItem (id) {
        let quantity = 0;
        const temp = JSON.parse(JSON.stringify(data))
        if(typeof id != 'undefined') {
            const id_index = temp.findIndex(t => t.ItemID === id);
            if(id_index > -1 ) {
                quantity = temp[temp.findIndex(t => t.ItemID === id)].qua 
            }
            
            
        }
        return quantity
    }
    // const hextoBase64 = (str) => {
    //     base64_string = btoa(String.fromCharCode.apply(null,str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")));
    //     return base64_string;
    // }

    useEffect(() => {
        if (processList && processList.length > 0 && ( !params.processID || params.processID == null )) {
            getOrderDetails(processList[0].PRID)
        }
    }, [processList])
    var time;
    useEffect(() => {
        if (showLoading) {
            if (time) {
                clearTimeout(time)
            }
            time = setTimeout(() => {
                setShowLoading(false)
            }, 1000)
        }
    }, [showLoading])

    return (
        <div className='body-details'>
            
            {isShow && (
                <>
            <Container fluid className='d-flex command-choices mb-2 '>
                <div className='checkboxes-commands'>
                    {  ordersList && ordersList.length > 0 &&
                        ordersList.map( (e, index) => {
                            // console.log(e)
                            return (
                                <div key={`comm-${index}`} className='flex-item' >
                                    <label><input type="radio" id={`comm-${e.POID}`} name="ordercheck" value={e.POID} onChange={() => { getProcesses(e.POID); getDetails(e.POID); }} checked={orderID == e.POID} /><span>{`(${e.code}) ${e.descr}`}</span></label>
                                    {/* <div className='detail-commands'>
                                        <h6>{e.descr}</h6>
                                    </div> */}
                                </div>
                            )
                        })
                    
                    }
                </div>
                
            </Container> 

            <div className='d-flex flex-column pb-2'>
                {/* <h5 className='py-2 bcolor '>Επιλογή Διεργασίας</h5> */}
                <div className='checkboxes-processes'>
                    {
                        processList && processList.length > 0 &&
                        processList.map( (e, index) => {
                            return (
                                <div key={`process-${index}`} className='flex-item'>
                                     <label><input type="radio" id={`proc-${e.PRID}`} name="processcheck" value={e.PRID} onChange={() => getOrderDetails(e.PRID)} checked={processID == e.PRID} /><span>{e.ProcessDescr}</span></label>
                                </div>
                            )
                            
                        })
                    
                    }
                </div>
            </div>
            <div className="comment-section">{comment}</div>
            </>
                )
            }   
            {!isShow && (
            <Container className='mt-4'>
                <h5 className='py-2 bcolor'>{i18n.t("Δεν έχετε ορίσει τις απαραίτητες παραμέτρους")}</h5>
            </Container>
            )}
            {isShow && (
            <Container fluid className=' mb-6'>
                <div className='App process-t'>
                    <DataTable
                    title={i18n.t("Είδη")}
                    columns= {columns}
                    data= {filteredItems}
                    selectableRows
                    onSelectedRowsChange = {SubmitHeaderComponetButton}
                    subHeader
                    contextActions={contextActions}
                    contextMessage={{
                        singular: i18n.t("είδος επιλέχθηκε"), 
                        plural: i18n.t("είδη επιλέχθηκαν"), 
                        message: ''
                    }}
                    clearSelectedRows={toggleCleared}
                    // actions = {
                    //     <button className="btn btn-success"  onClick={handleInsert}>Αποθήκευση αλλαγών</button>
                    // }
                    expandableRows
                    expandableRowExpanded={(row) => (row === currentRow)}
                    onRowExpandToggled={(bool, row) => setCurrentRow(row)}
                    expandableRowsComponent={ExpandedComponent}
                    subHeaderComponent={SearhHeaderComponent}
                    selectableRowsHighlight
                    defaultSortField="title"
                    onTableUpdate={onTableUpdate}
                    noDataComponent={i18n.t("Δεν υπάρχουν εγγραφές")}
                    paginationComponentOptions={
                        { 
                            rowsPerPageText: `${i18n.t('Γραμμές ανά σελίδα')}:`, 
                            rangeSeparatorText: i18n.t('από'), 
                            noRowsPerPage: false, 
                            selectAllRowsItem: false, 
                            selectAllRowsItemText: i18n.t('Όλα')
                        }
                    }></DataTable>
                    {
                        filteredItems.length > 0 &&
                        <div className='table-row-counter'>{i18n.t('Γραμμές στην διεργασία')}: {filteredItems.length} </div>
                    }
                </div>
            </Container>)}
            <Modal show={modalconshow} onHide={handleModalConClose} dialogClassName="modalconsumes modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>{i18n.t('Αναλώσεις Εντολής')}</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <DataTable
                            tile={i18n.t('Αναλώσεις Εντολής')}
                            columns= {consume_columns}
                            data= {consume}
                            noDataComponent={i18n.t("Δεν υπάρχουν εγγραφές")}
                            ></DataTable>
                        </div>
                    </Modal.Body>
            </Modal>
            <Modal show={show} onHide={handleClose}  dialogClassName="modal-90w" >
                <Modal.Header closeButton>
                    <Modal.Title>{i18n.t("Σκάρτα Προιόντα")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-row row new-scrap">
                            <div className="col-lg-4 col-md-6 pb-2">
                                
                                <Select className='wasteselect' onChange={e => handleAddWaste(e, 'wastetype', setSelectWtype)} 
                                    options={wastetypeList.map(obj =>
                                        { 
                                            return {
                                                value: obj.WTID,
                                                label: obj.Descr 
                                            }
                                        } 
                                    )}
                                    value={selectedWtype || ''} isSearchable={false} placeholder={i18n.t("Επιλέξτε τύπο σκάρτου")} id='wasteselect' style={{minHeight:'56px'}}>
                                </Select>    
                            </div>
                            
                            <div className="col-lg-4 col-md-6 pb-2">
                                <input type='number' name='wastequa' id='wastequa' className="form-control" onChange= { (a) => handleAddWaste(a.target.value, 'wastequa')} value = {addwasterow.wastequa} min="1" max = { quantityItem( selectitem)} placeholder={i18n.t("Ποσότητα")} />
                            </div>
                            <div className="col-lg-4 col-md-6 pb-2">
                                <input type="text" name="comments" id='wastecomments' className="form-control" value={addwasterow.comments} onChange = {(a) =>  handleAddWaste(a.target.value, 'comments')} placeholder={i18n.t("Σχόλια")} />
                            </div>
                            <div className="col-lg-4 col-md-6 pb-2">
                            <button type="button" className="btn btn-success" onClick={addWastereq}>{i18n.t("Προσθήκη")}</button>
                            </div>
                    </div>     
                    <div className='scrap-products'>
                    <DataTable
                    tile={i18n.t("Σκάρτα Προιόντα")}
                    columns= {product_colums}
                    data= {scrap}
                    noDataComponent={i18n.t("Δεν υπάρχουν εγγραφές")}
                    ></DataTable>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <button type='button' onClick={openAddModal} className='btn btn-success'>Προσθήκη</button> */}
                    {/* <button type="button" onClick={handleClose} className="btn btn-primary" >
                       Αποθήκευση
                    </button> */}
                </Modal.Footer>
            </Modal>
            <Modal show={modalshow} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{i18n.t("Προσθήκη σκάρτου")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={handleModalClose} className="btn btn-primary" >
                       {i18n.t("Αποθήκευση")}
                    </button>
                </Modal.Footer>
            </Modal>
            <Loader show={showLoading}/>
        </div>
    )
}
export default Production;