import Container from 'react-bootstrap/Container';
import { useState, useEffect } from 'react';
import { Edit, Send, MessageCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import storage from '../../storage';
import './Footer.css'
import request from '../../request';
import history from '../../history';

function Footer(props) {
    const [newMessages, setNewMessages] = useState([])

    useEffect(() => {
        checkMessages(props.showMessageBtn)
        const t = setInterval(() => {
            checkMessages(props.showMessageBtn)
        }, 10000)
    
        return () => {
          clearInterval(t)
        }
    }, [])
    
    const checkMessages = (run) => {
        if (run) {
            request.get('/checkmessages').then(response => {
                if(response.data) {
                    setNewMessages(response.data)
                }
            })
        }
    }
    
    const readMessage = (message) => {
        const id = message.RID
        setNewMessages(newMessages.filter(n => n.RID !== id))
        request.post('/readmessage', {
            msg_id: [id]
        }).then(
            response => {
                // if (response.data.m == 1) {
                //     // checkMessages()
                // }
            }
        )
    }

    const goToMessage = (message) => {
        history.push(`/messages/${message.Rsender}`)
        // const id = message.RID
        // setNewMessages(newMessages.filter(n => n.RID !== id))
        // request.post('/readmessage', {
        //     msg_id: [id]
        // }).then(
        //     response => {
        //         // if (response.data.m == 1) {
        //         //     // checkMessages()
        //         // }
        //     }
        // )
    }

    return (
        <Container>
            {
                props.showMessageBtn &&
                <div>
                    {
                    (newMessages && newMessages.length > 0) &&
                    <div className="footer-unread-msgs">
                        {
                            newMessages.map((m, i) => {
                                return (
                                    <div className="footer-unread-msg" key={`unread-msg-${i}`}>
                                        <h6 className="msg-pre-text">{i18n.t("Νέο μήνυμα από")}</h6>
                                        <h6>{m.Ruser}</h6>
                                        <hr/>
                                        <span>{m.Rtext}</span>
                                        <div className="message-actions">
                                            <button onClick={() => readMessage(m)}>{i18n.t("Εντάξει")}</button>
                                            <button onClick={() => goToMessage(m)}>{i18n.t("Απάντηση")}</button>
                                        </div>
                                        
                                    </div>
                                )
                            })
                        }
                    </div>
                    }
                    
                    <Link className='float' style= {{color:'#fff', textDecoration:'none'}} to='/messages'>
                        <span className="notif-msg">{(newMessages && newMessages.length) && newMessages.length}</span>
                        <span>{i18n.t("Μηνύματα")} </span>
                        <MessageCircle className='float-icon' size='20' color='#fff' />
                    </Link>
                    
                
                </div>
            }
        </Container>
        
    )
}
export default Footer;