import Flatpickr from "react-flatpickr";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/js/bootstrap.js';
import Select from 'react-select';
import "flatpickr/dist/themes/material_blue.css";
import './Messages.css';
import storage from '../../storage';
import request from '../../request';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from "react-router-dom";
import moment from 'moment';
import DataTable from "react-data-table-component";
import React, { useMemo } from "react"; //Pass a “create” function and an array of dependencies. useMemo will only recompute the memoized value when one of the dependencies has changed. This optimization helps to avoid expensive calculations on every render.
// import FilterComponent from "./Filter";
import { Edit, User, CheckCircle, MoreVertical, CornerDownLeft, ArrowLeft } from 'react-feather';
import Table from 'react-bootstrap/Table';
import history from "../../history";
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import i18n from "../../i18n";
import utils from "../../Utils";

var fetchingMessages = false;

function Messages(props) {
    const params = useParams();

    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectUser] = useState([])
    const [showNewMessage, setShowNewMessage] = useState(true)
    const [selectedText , setSelectText] = useState('')
    const [connectedUsersList, setConnectedUsersList] = useStateWithCallbackLazy([]);
    const [messagesList, setMessagesList] = useState([])
    const [conversationUser, setConversationUser] = useState({})
    const [lastScrollHeight, setLastScrollHeight] = useState(0)
    const [returnMessage, setReturnMessage] = useState('')
    const [relateMessage, setRelateMessage] = useState('')
    const [unreadMessages, setUnreadMessages] = useState([])
    // const [fetchingMessages, setFetchingMessages] = useState(false)

    const scrolltoRef = useRef(null)
    const firstelem = useRef(null)
    const usersRef = useRef(null);
    const convUserRef = useRef(null);

    useEffect(() => {
        usersRef.current = connectedUsersList;
        convUserRef.current = conversationUser;
    })
    
    /*Requests for messsages component */

    const getConnected = (initial = false) => {
        request.get('/messages').then(response => {
            if(response.data) {
                // console.log(response.data)
                if (initial && params.userMessageID) {
                    setConnectedUsersList(response.data, () => {

                        setTimeout(() => {
                            if (response.data.filter(r => r.userid === params.userMessageID).length > 0) {
                                // setConversationUser(t.filter(a => a.username === conversationUser.username)[0])
                                handleUnRead(response.data.filter(a => a.userid === params.userMessageID)[0], response.data)
                                handleShowChat(response.data.filter(a => a.userid === params.userMessageID)[0])
                            }
                        }, 500)
                    })
                } else {
                    setConnectedUsersList(response.data);
                }
                
            }
        })
    }

    const getAll = () => {
        request.get('/users').then(response => {
            if(response.data) {
                // console.log(response.data);
                setUserList(response.data);
            }
        })
    }

    const newMessage = () => {
        request.post('/newmessage', {
            usersid: selectedUser.map ( (e) => {
                return e.value
            }),
            text: selectedText,
            relate: 0

        }).then(
            response => {
                // console.log(response.data)
                getConnected()
                setSelectText('')
            }
        )
    }

    const newMessageConnected = () => {
        let relate_id = 0;
        if(returnMessage !='') {
            relate_id = returnMessage.RID
        }
        request.post('/newmessage', {
            usersid: [conversationUser.userid],
            text: selectedText,
            relate: relate_id
        }).then(
            response => {
                // console.log(response.data);
                setMessagesList(response.data.map(a => {
                    return {
                        ...a, //row of message
                        time_ago: utils.timeSince((a.mydate))
                    }
                }))
                getConnected();
                setSelectText('')
                setReturnMessage('')
                document.querySelector('.chat-body').scrollTop = document.querySelector('.chat-body').scrollHeight
                // handleShowChat(conversationUser)
                // if(returnMessage != '') {

                // }
              
            }
        )

        
    }

    const checkMessages = () => {
        // const users = JSON.parse(JSON.stringify(usersRef.current))
            request.get('/checkmessages').then(response => {
                if (response.data) {
                    setUnreadMessages(response.data)
                    
                    // console.log(response.data);
                    // let count = response.data.length
                    // storage.set('new_msg_count',count);
                    // console.log(storage.new_msg_count)
                }
            })
        
        
    }

    useEffect(() => {
        if (unreadMessages.length > 0) {
            const t = JSON.parse(JSON.stringify(connectedUsersList)).map(c => {
                const d = unreadMessages.filter(r => r.Ruser === c.username)
                if (d && d.length > 0) {
                    return {
                        ...c,
                        mytext: d[d.length - 1].Rtext,
                        unread_msgs: d
                    }
                }
                return c
            })
            
            // console.log(t)
            if (t) {
                setConnectedUsersList(t, () => {

                    setTimeout(() => {
                        if (unreadMessages.filter(r => r.Ruser === conversationUser.username).length > 0) {
                            // setConversationUser(t.filter(a => a.username === conversationUser.username)[0])
                            handleUnRead(t.filter(a => a.username === conversationUser.username)[0], t)
                            handleShowChat(t.filter(a => a.username === conversationUser.username)[0])
                        }
                    }, 500)
                })
            }

            
        }
    }, [unreadMessages])
    const handleUnRead = (e, new_list = null) => {

        if(e.unread_msgs.length > 0) {
            request.post('/readmessage', {
                msg_id: e.unread_msgs.map( (e) => {
                    return e.RID
                })
            }).then(
                response => {
                    // console.log(response.data)
                    if(response.data.m == 1) {
                        const t = new_list || JSON.parse(JSON.stringify(connectedUsersList)).map(a => {
                            if (a.userid === e.userid) {
                                a.unread_msgs = []
                            }

                            return a
                        })


                        setConnectedUsersList(t)
                        // document.querySelector(`#notif-gitses-${e.userid}`).style.display = 'none'
                        // console.log(response.data);
                        
                    }
                    
                }
            )
        }
        
    }

    

    const handleShowChat = (recipient) => {
        // console.log(recipient)
        setShowNewMessage(false);
        let currentdate = new Date();
        // const HistoryMessage = () => {
            let recipient_id ='';
            if(recipient.usertype == 2) {
                recipient_id = recipient.myuser
                document.getElementById('chtinput').disabled = true;
                document.getElementById('sendit').disabled = true;
            }else{
                recipient_id = recipient.userid
                document.getElementById('chtinput').disabled = false;
                document.getElementById('sendit').disabled = false;
            }
            request.post('/historymessage', {
                user_recipient: recipient_id,
                datetime: setCustomDate(currentdate)
            }).then(response => {
                if(response.data){
                    setMessagesList(response.data.map(a => {
                        return {
                            ...a,
                            time_ago: utils.timeSince((a.mydate))
                        }
                    }))
                    fetchingMessages = true
                    setConversationUser(recipient)
                    
                    let last_elem = response.data[0]
                }
                document.querySelectorAll(".user-itm").forEach(
                    (e) => {
                        e.addEventListener('click', function () {
                            if (window.innerWidth < 426) {
                                document.querySelector('.chat-sidebar').classList.add('hide-sidebar');
                                document.querySelector('.chat-conversation').classList.add('show-chatbar');
                            }
                        })
                    }
                )
                
                history.push(`/messages/${recipient.userid}`)
            })
            
        // }
    }

    const OlderMessages = useCallback( () => {
        if(firstelem != null && isInViewport(firstelem) && !fetchingMessages && messagesList.length > 0) {
            fetchingMessages = true
            setLastScrollHeight(document.querySelector('.chat-body').scrollHeight)
            request.post('/historymessage', {
                user_recipient: conversationUser.userid,
                datetime: setCustomDate(messagesList[0].mydate)
            }).then(response => {
                if(response.data) {
                    if (response.data.length === 0) {
                        // document.querySelector('.conversation-details').removeEventListener("scroll", t, { passive: false })
                    } else {
                        setMessagesList(
                            response.data.concat(messagesList).map(a => {
                                return {
                                    ...a,
                                    time_ago: utils.timeSince((a.mydate))
                                }
                            })
                        )
                    }
                    
                    // append()
                }
                
            })
        } else {
            // console.log(firstelem, fetchingMessages, messagesList)
        }
    }, [conversationUser, messagesList])


    const handleReply = ((e) => {
        request.get(`/returnmessage/${e.RID}`).then(
            response => {
                if(response.data) {
                    setReturnMessage(response.data)
                }
            }
        )
    }) 
    
    /* End of requests */

    function isInViewport(element) {
        // console.log(element)
        if (element.current) {
            const rect = element.current.getBoundingClientRect();
            return (
                rect.top >= document.querySelector('.chat-body').getBoundingClientRect().top
            );
        }

        return false
    }
    
    function setCustomDate(date) {
        const d = `${moment(date).format("YYYY-MM-DDTHH:mm:ss")}Z`
        
        return d
    }

    const handleShowOptions = () => {
        setShowNewMessage(true)
        history.push(`/messages`)
        
        if (window.innerWidth < 426) {
            document.querySelector('.chat-sidebar').classList.add('hide-sidebar');
            document.querySelector('.options-chat.chat-conversation').classList.add('show-chatbar');
        }
    }
    const handleUsers = (e) => {
        setSelectUser(e) 
    }
    const handleNewMessage = (e) => {
        setSelectText(e);
    }

    const t = useCallback( () => {
        OlderMessages()
    }, [messagesList, conversationUser])

    const timeMessages = () => {
        if (messagesList && messagesList.length > 0) {
            setMessagesList(messagesList.map(a => {
                return {
                    ...a,
                    time_ago: utils.timeSince((a.mydate))
                }
            }))
        }
    }

    useEffect( () => {
        // const t = function(e){
        //     OlderMessages()
        // }
        document.querySelector('.chat-body').removeEventListener("scroll", t, { passive: false });
        document.querySelector('.chat-body').addEventListener("scroll", t, { passive: false })
        setTimeout(() => {
            fetchingMessages = false
        }, 500)
        return () => {
            document.querySelector('.chat-body').removeEventListener("scroll", t, { passive: false });
        }
    }, [messagesList, conversationUser])

    useEffect( () => {
        getConnected(true);
        getAll();
        // checkMessages();
        props.showHide(false)

        const t = setInterval(checkMessages, 5000)
        // const v = setInterval(timeMessages, 10000)
        return () => {
            props.showHide(true)
            clearInterval(t)
            // clearInterval(v)
        }
    }, [])

    // useEffect( () => {
    //     if(scrolltoRef != null) {
    //         scrolltoRef.current.scrollIntoView({ behavior: "smooth" });
    //     }
        
    // }, [])



    useEffect( () => {
        let scrollDiff = document.querySelector('.chat-body').scrollHeight - lastScrollHeight;
        document.querySelector('.chat-body').scrollTop += scrollDiff;

        setLastScrollHeight(document.querySelector('.chat-body').scrollHeight)
        setTimeout(() => {
            fetchingMessages = false
        }, 500)
    }, [messagesList])

    useEffect( () => {
        setSelectText('')
        // if (showNewMessage) document.querySelector('.chat-sidebar').classList.add('hide-sidebar');
        // else document.querySelector('.chat-sidebar').classList.remove('hide-sidebar');
    }, [showNewMessage])

    useEffect( () => {
        setReturnMessage('')
    }, [conversationUser])

    const handleBack = () => {
        // document.querySelector(".back").addEventListener('click', function () {
            if (window.innerWidth < 426) {
                document.querySelector('.chat-sidebar').classList.remove('hide-sidebar');
                document.querySelector('.chat-conversation').classList.remove('show-chatbar');
            }
        // })
    }

    const handleNewMsgBack = () => {
        // document.querySelector(".back").addEventListener('click', function () {
            if (window.innerWidth < 426) {
                document.querySelector('.chat-sidebar').classList.remove('hide-sidebar');
                document.querySelector('.options-chat.chat-conversation').classList.remove('show-chatbar');
            }
        // })
    }

    return (
        <div className="d-flex w-100 chatpage">
            
                <div className='chat-sidebar'>
                    <div className="chat-list">
                        <div className="left-chat-title d-flex justify-content-between 
                        align-items-center ps-0 pe-0">
                            <div className="chat-title">
                            <h4>{i18n.t("Πρόσφατες συνομιλίες")}</h4>
                            </div>
                            <div className="add-newchat">
                                <button type="button" className='newmesg' to="#" onClick= { () => handleShowOptions()}>
                                    <Edit size="20"/>
                                </button>
                            </div>
                        </div>
                        <div className="chat-inside">
                            <div className="chat-sidebar-body">
                                <ul className="users-list">
                                    {
                                        connectedUsersList && connectedUsersList.length > 0 &&
                                        connectedUsersList.map( (e, index) => {
                                            return (
                                                <li key = {`user-${index}`} className="user-itm" 
                                                onClick = { () => 
                                                        {
                                                            handleShowChat(e);
                                                            handleUnRead(e);
                                                        }
                                                }>
                                                    <div className='user-img'>
                                                        <User size='20' />
                                                    </div>
                                                    <div className='userchats'>
                                                        <div className='inner'>
                                                            <h6>{e.usertype == 1 ? e.username : i18n.t("Συστημικός Χρήστης")}</h6>
                                                            <span className={`msg-preview ${e.unread_msgs.length > 0 && 'msg-preview-bold'}`}>{`${e.mytext}`}</span>
                                                            
                                                        </div>
                                                           
                                                    </div>
                                                    {
                                                        e.unread_msgs.length > 0 && (
                                                            <div className="notif">
                                                                <div className='notification-unread' id={`notif-gitses-${e.userid}`}>
                                                                    {e.unread_msgs.length}
                                                                    {/* {e.unread_msgs.forEach(function(e){
                                                                        console.log(e.RID)
                                        })} */}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                
                    
                <div className={`chat-conversation ${showNewMessage ? 'chat-conversation-hidden' : 'show-chatbar'}`}>
                    <div className='conversation-inner'>
                        <div className='conversation-details'>
                            <div className="chat-header">
                                <div className="user-details">
                                    <div className="back" onClick={handleBack}><ArrowLeft></ArrowLeft></div>
                                    <User size='35' />
                                    <div className="mt-1 user-inner">
                                    
                                    <h5>{conversationUser && conversationUser.username ? conversationUser.username : 'Συστημικός Χρήστης'}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="chat-body">
                                <div className="msgs">
                                    <div ref={firstelem}></div>
                                    {messagesList &&
                                        messagesList.map( (e,index) => {
                                            return (
                                                    <div key={`message-${index}`}>
                                                        {
                                                        !e.mymessage ?
                                                        <div className="msg-recipient-wrapper">
                                                            {
                                                                e.related_msg &&
                                                                <div className='replymsg recipient-replymsg'>
                                                                    <div className="msginner">
                                                                        <span className="answer-top">{i18n.t("Απάντηση στο")}: </span>
                                                                        <span>{e.related_msg.Rtext}</span>
                                                                    </div>
                                                                </div>      
                                                            }
                                                            <div className={`msg-recipient ${e.related_msg && 'msg-recipient-with-reply'}`}>
                                                            
                                                                <div className="msg-cont">
                                                                    <div className="msg-inner">
                                                                        <span>{e.mytext}</span>
                                                                    </div>
                                                                    <div className='msg-name'>
                                                                        <h6>{conversationUser && conversationUser.username ? conversationUser.username : ''} <span className="time-ago">{e.time_ago}</span>  </h6>
                                                                          
                                                                        {/* <button className="btn btn-info readit">Το διάβασα</button> */}
                                                                        
                                                                    </div>
                                                                </div>
                                                                { conversationUser.usertype == 1 && (
                                                                    <div className="dropdown">
                                                                        <button className="btn" type="button" id="dropdownMenuButto1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <MoreVertical></MoreVertical>
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButto1">
                                                                                <Link to='#' onClick={() => handleReply(e)}><li className='replymsg'>{i18n.t("Απάντηση")}<CornerDownLeft></CornerDownLeft></li></Link>
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                                
                                                            </div>         
                                                        </div>
                                                    
                                                    :
                                                    <div className={`msg-your ${e.related_msg && 'msg-your-with-reply'}`}>
                                                        {
                                                            e.related_msg &&
                                                            <div className='replymsg your-replymsg'>
                                                                <div className="msginner">
                                                                    <span className="answer-top">{i18n.t("Απάντηση στο")}: </span>
                                                                    <span className="answer">{e.related_msg.Rtext}</span>
                                                                </div>
                                                            </div>      
                                                        }
                                                        
                                                        <div className="msg-inner">
                                                            <span>{e.mytext}
                                                            </span>
                                                        </div>
                                                        <div className='msg-name'>
                                                            <h6><span className="time-ago">{e.time_ago}</span>   {i18n.t("Εγώ")}</h6>
                                                            
                                                        </div>
                                                    </div>
                                                    }
                                                    
                                                </div>
                                            )
                                        })
                                    
                                    }
                                    <div ref={scrolltoRef}></div>
                                </div>
                            </div>
                            <div className="chat-footer">
                                { returnMessage != '' && (
                                    <div className='return-inner'>
                                        <div className='cancel-returnmsg' onClick={() => setReturnMessage('')}>x</div>
                                        <div className="returnto">
                                            <span>{i18n.t("Απάντηση στον χρήστη")}: <b>{conversationUser.username}</b></span>
                                        </div>
                                        <div className="returntomsg">
                                            <span>{returnMessage.Rtext}</span>
                                        </div>
                                    </div>
                                )}
                                <div className='new-msg-area'>

                                <input type="text" className="chat_form" id='chtinput'
                                    name="chatext"
                                    value={selectedText}
                                    onChange={e => handleNewMessage(e.target.value, 'chatext')}
                                    onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
                                            newMessageConnected()
                                        }
                                    }}
                                    placeholder={`${i18n.t("Πληκτρολογήστε το μήνυμά σας")}....`} />
                                    
                                    <div className="form-buttons">
                                        <button className="btn send-btn" onClick={newMessageConnected} type="button" id='sendit'>
                                            <span>{i18n.t("Αποστολή")}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
                
                <div className={`form-row options-chat chat-conversation ${!showNewMessage ? 'chat-conversation-hidden' : 'show-chatbar'}`}>
                    <div className="pb-4 pt-4">
                        <div className="back" onClick={handleNewMsgBack}><ArrowLeft></ArrowLeft></div>
                        <h5>{i18n.t("Δημιουργία νέας συζήτησης")}</h5>
                        {/* <pre>{JSON.stringify(selectedUser)}</pre> */}
                        <Select className="users-select" isMulti
                        options={userList.map(obj =>
                        {
                            return {
                                value: obj.userid,
                                label: obj.username
                            }
                        })}
                        onChange ={handleUsers}
                        value={selectedUser} 
                        isSearchable={false} 
                        placeholder={i18n.t("Επιλέξτε παραλήπτες")}  
                        style={{minHeight:'56px'}}>

                        </Select>
                    </div>
                    <div className='new-msg-area'>
                    {/* {JSON.stringify(selectedText)} */}
                        <input type="text" name="comments" className="form-control chat_form" 
                        value = {selectedText}
                        onChange={e => handleNewMessage(e.target.value, 'comments')} 
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                newMessage()
                            }
                        }}
                        placeholder={`${i18n.t("Πληκτρολογήστε το μήνυμά σας")}....`} />
                        <div className="form-buttons">
                            <button className="btn send-btn" onClick={newMessage} type="button">
                                <span className="material-icons">{i18n.t("Αποστολή")}</span>
                            </button>
                        </div>
                    </div>
                    
                </div>
                
        </div>
    )
}
export default Messages;