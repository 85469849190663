import Flatpickr from "react-flatpickr";
import { Greek } from "flatpickr/dist/l10n/gr.js";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import "flatpickr/dist/themes/material_blue.css";
import './Machine.css';
import storage from '../../storage';
import request from '../../request';
import { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import moment from 'moment';
import React, { useMemo } from "react"; //Pass a “create” function and an array of dependencies. useMemo will only recompute the memoized value when one of the dependencies has changed. This optimization helps to avoid expensive calculations on every render.
//  import FilterComponent from "./Filter";
import history from "../../history";
import Swal from 'sweetalert2';
import i18n from "../../i18n";

function Machine(props) {
    const params = useParams();

    const [machineList, setMachineList] = useState([])
    const [selectedmachine, setSelectMachine] = useState() //for object
    const [shiftList, setShiftList] = useState([])
    const [selectedShift, setSelectShift] = useState()
    const [selecteddate, setSelectDate] = useState(new Date());
    const [ordersList, setOrdersList] = useState(null)
    const [required, setRequired] = useState(false);
    
    const [errors, setErrors] = useState({
        select_machine: false,
        select_shift: false
    })
    const fp = useRef(null);
    /* Machine List */
    const getMachines = () => {
        if (!storage.machines || typeof storage.machines == 'undefined' || storage.machines === null){
            request.get('/machines').then(response => {
                if (response.data) {
                    setMachineList(response.data)
                    storage.set('machines', response.data)
                    if (storage.machine_id) {
                        const m = response.data.find(a => parseInt(a.MID) === parseInt(storage.machine_id))
                        if (typeof m !== 'undefined') {
                            
                            setSelectMachine({
                                label: `${m.MachineDescr}`,
                                value: m.MID,
                                name: m.MachineDescr
                            })
                        }
                    }
                }
            })
        } else {
            setMachineList(storage.machines)
            if (storage.machine_id) {
                const m = storage.machines.find(a => parseInt(a.MID) === parseInt(storage.machine_id))
                if (typeof m !== 'undefined') {
                    
                    setSelectMachine({
                        label: `${m.MachineDescr}`,
                        value: m.MID,
                        name: m.MachineDescr
                    })
                }
            }
        }
        
    }
    /*End of Machine List */

    /* Shifts List */
    const getShifts = () => {
        if (!storage.shifts || typeof storage.shifts == 'undefined' || storage.shifts === null) {
            request.get('/shifts').then(response => {
                if (response.data) {
                    setShiftList(response.data)
                    storage.set('shifts', response.data)
                    if (storage.shift_id) {
                        const m = response.data.find(a => parseInt(a.SHFTID) === parseInt(storage.shift_id))
                        if (typeof m !== 'undefined') {
                            setSelectShift({
                                label: m.shiftdescr,
                                value: m.SHFTID
                            })
                        }
                    }
                }
            })
        } else {
            setShiftList(storage.shifts)
            if (storage.shift_id) {
                const m = storage.shifts.find(a => parseInt(a.SHFTID) === parseInt(storage.shift_id))
                if (typeof m !== 'undefined') {
                    setSelectShift({
                        label: m.shiftdescr,
                        value: m.SHFTID
                    })
                }
            }
        }
        
    }
    /* End of Machine List */

    /* Validate data */
    function validateData() {
        const temp_errors = JSON.parse(JSON.stringify(errors))

        temp_errors.select_machine = !selectedmachine || selectedmachine.value == ''
        temp_errors.select_shift = !selectedShift || selectedShift.value == ''
        var valid = true
        var error_txt;
        Object.entries(temp_errors).map(item => { //object entries metatrepei ena object se array kai sthn sunexeia kai me to map ginetia h ruthmish
            if (item[1]) {
                valid = false
            }
            // console.log(item)
            
        })
        if(!valid) {
            if(temp_errors.select_machine && temp_errors.select_shift) {
                error_txt = i18n.t("στα πεδία μηχανή και βάρδια");
            }else if(temp_errors.select_machine) {
                error_txt = i18n.t("στο πεδίο μηχανή");
            }else if(temp_errors.select_shift) {
                error_txt = i18n.t("στο πεδίο βάρδια");
            }

            Swal.fire({
                icon: 'warning',
                title: `${i18n.t("Παρακαλώ όπως καταχωρήσετε τα βασικά στοιχεία")} ${error_txt}`,
                confirmButtonColor: '#3085d6',
            })
        }
        setErrors(temp_errors)
        // if(temp_errors.select_machine && temp_errors.select_shift) {
            
        // }
            
       
        return valid
    }

    /* End of validate data */

    const handleSubmit = (e) => {
        if (typeof e.preventDefault === "function") { 
            e.preventDefault()
        }

        if (validateData()) {
            storage.set('machine_id', selectedmachine.value);
            storage.set('machine_name', selectedmachine.name);
            storage.set('shift_id', selectedShift.value);
            storage.set('shift_name',selectedShift.label);
            storage.set('date', selecteddate);
            props.showHideLoading(true)
            request.get(`/machineorders/${storage.machine_id}`).then( 
                response => {
                    // console.log(response.data);
                    const d = Object.entries(response.data).map( item => {
                        // console.log(item[1]);
                        return (
                            
                                item[1]
                        )
                    })
                    setOrdersList(d)
                    
                    // console.log(d)
                    history.push(`/production/${selectedmachine.value}/${selectedShift.value}`)
                    props.showHideLoading(false)
                }
            )
        }
    }

    useEffect(() => {
        getMachines();
        getShifts();
        // console.log(fp)
        // fp.current.flatpickr.l10n = Greek;
        // fp.current.flatpickr.redraw()
    }, [])

    useEffect(() => { 
        if (params.shiftID && params.machineID) {
            handleSubmit({})
        }
        if (storage.date) {
            setCustomDate(storage.date, setSelectDate)
        }
    }, [selectedmachine, selectedShift]) //exei times ara onchange

    const handleChange = (selectedoption, fn) => {

        // if(selectedoption.name)
        fn(selectedoption);
    }

    function setCustomDate(date, fn) {
        const d = `${moment(date).format("YYYY-MM-DDT00:00:00.000")}Z`
        
        fn(d)
    }

    return (
        <div className="body-details">
             <Container className='mt-4'>
                <h5 className='py-2 bcolor'>{i18n.t("Καταχώρηση βασικών στοιχείων")}</h5>
                <Form className="form-prd" onSubmit={handleSubmit}>
                    <Row className='justify-content-center'>
                        <Col lg={4} md={6}>
                        <Form.Group className="mb-3 form-d" controlId="form-machine">
                            {/* {
                                errors.select_machine &&
                                <span>Error on select machine</span>
                            } */}
                            <Form.Label>{i18n.t("Επιλογή μηχανής")}</Form.Label>
                            <Select onChange={e => handleChange(e, setSelectMachine)} 
                                options={machineList.map(obj =>
                                    { 
                                        return {
                                            value: obj.MID,
                                            label: `${obj.MachineDescr}` , //(${obj.mshortdescr})
                                            name: obj.MachineDescr
                                        }
                                    } 
                                )}
                                value={selectedmachine} isSearchable={false}   required={required} placeholder={i18n.t("Επιλέξτε μηχανή")} >
                            </Select> 
                        </Form.Group>
                        </Col>
                        <Col lg={4} md={6}>
                            <Form.Group className="mb-3 form-d" controlId="form-shift">
                            {/* {
                                errors.select_shift &&
                                <span>Error on select shift</span>
                            } */}
                            <Form.Label>{i18n.t("Επιλογή βάρδιας")}</Form.Label>
                            <Select onChange={e => handleChange(e, setSelectShift)} 
                                options={shiftList.map(obj =>
                                    { 
                                        return {
                                            value: obj.SHFTID,
                                            label: obj.shiftdescr 
                                        }
                                    } 
                                )}
                                value={selectedShift} isSearchable={false} placeholder={i18n.t("Επιλέξτε βάρδια")} >
                            </Select>  
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={6}>
                        <Form.Group className="mb-3 form-d">
                            <Form.Label>{i18n.t("Επιλογή ημερομηνίας")}</Form.Label>
                            <Flatpickr 
                                ref={fp}
                                className="c-date-picker" 
                                 placeholder={i18n.t("Επιλέξτε ημερομηνία")}
                                options={{ dateFormat: "d-m-Y", locale: i18n.get_date_locale() }}
                                value={selecteddate}
                                onChange={(date) => {
                                    setCustomDate(date[0], setSelectDate);
                                }}
                                 />
                        </Form.Group>
                        </Col>
                        <Col lg={12} className='text-center'>
                            <button className='login-button' type="submit">
                                {i18n.t("Αποθήκευση")}
                            </button>  
                        </Col>
                    </Row>    
                </Form>
            </Container>
        </div>
       
    )
}
export default Machine;