import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import config from '../../Config';
import './Header.css'
import storage from '../../storage';
import request from '../../request';
import moment from 'moment';
import history from '../../history';
import i18n from '../../i18n';
import LanguageSelect from "../language-select/LanguageSelect";

function Header(props) {
    // const [selected_choice, setActive] = useState(false);

    // const handleClick = event => {
    //     // setActive(current =>!current);
    //     event.currentTarget.classList.toggle('selected-choice');
    // };
    const historyActive = () => {
        const slash_num = (config.basename.match(new RegExp("/", "g")) || []).length
        let path = window.location.pathname.split('/')[slash_num]
        
        if (path == 'production') {
            return 0
        }
        if (path == 'machine') {
            return 1
        }
        if (path == 'delays') {
            return 2
        }
    }
    const [active, setActive] = useState(historyActive())
    const [checked, setCheck] = useState(false) 

    // const [, setNew] = useState(false)
    const [storageMeta, setStorageMeta] = useState({
        machine_name: storage.machine_name ? storage.machine_name : '',
        machine_id: storage.machine_id && typeof storage.machine_id !== 'undefined' && storage.machine_id != null ? storage.machine_id : false,
        shift_name: storage.shift_name ? storage.shift_name : '',
        shift_id: storage.shift_id && typeof storage.shift_id !== 'undefined' ? storage.shift_id : false,
        order_id: storage.order_id && typeof storage.order_id !== 'undefined' ? storage.order_id : false,
        comment_order: storage.comment_order && typeof storage.comment_order !== 'undefined' ? storage.comment_order : false,
        process_id: storage.process_id && typeof storage.process_id !== 'undefined' ? storage.process_id : false,
        date: storage.date ? storage.date : '',
    })

    const getActive = (e) => {
        if (e === active) {
            return 'active'
        }else {
            return ''
        }
    }
    
    useEffect(() => {
        history.setCustomChangeEvent(() => {
            setActive(historyActive())
        })
        if(storage.machine_status == 1) {
            setCheck(true);
        }else{
            setCheck(false);
        }
        const storage_on_change_unset = storage.onChange((e) => {
            if (storage.machine_status && typeof storage.machine_status !== 'undefined' && storage.machine_status != null && storage.machine_id != null) {
                // setNew(storage.machine_status === 1)
                setCheck(storage.machine_status === 1)
            }
            if (storage.machine_id != '' && storage.machine_id != 'undefined' && storage.machine_id != null && storage.machine_id !== storageMeta.machine_id) {
                machineStatus()
            
                setStorageMeta({
                    machine_name: storage.machine_name ? storage.machine_name : '',
                    shift_name: storage.shift_name ? storage.shift_name : '',
                    machine_id: storage.machine_id && typeof storage.machine_id !== 'undefined'  ? storage.machine_id : null,
                    shift_id: storage.shift_id && typeof storage.shift_id !== 'undefined'  ? storage.shift_id : null,
                    order_id: storage.order_id && typeof storage.order_id !== 'undefined' ? storage.order_id : null,
                    comment_order: storage.comment_order && typeof storage.comment_order !== 'undefined' ? storage.comment_order : false,
                    process_id: storage.process_id && typeof storage.process_id !== 'undefined' ? storage.process_id : null,
                    date: storage.date ? storage.date : '',
                })
            }
        })

        // if (storageMeta.machine_id != '' && storageMeta.machine_id != 'undefined' && storageMeta.machine_id != 'null') {
        //     machineStatus()
        // }
            
        return () => {
            storage.clearOnChange(storage_on_change_unset)    
        }
    }, [])

    const changemachinestatus = (e) => {
        
        let status = 0
        if (e.target.checked) {
            status = 1
        }
        setCheck(status === 1)
        request.post('/changestatus',
        {
            machine_id: storage.machine_id,
            machine_status: status
            
        }).then(
            response => {
                // console.log(response.data.res);
                if(response.data.res == 1) {
                    // setCheck(status === 1)
                    // storage.set('new_mstatus', newstatus);
                }
            }
            
        )
    }
    
    const machineStatus = () => {
        if (storage.machine_id && typeof storage.machine_id != 'undefined' && storage.machine_id !== null) {
            request.get(`/machinestatus/${storage.machine_id}`).then(
                response => {
                    // console.log(response.data)
                    if(response.data != null) {
                        //storage.set('machine_status', response.data.status);
                        setCheck(response.data.status === 1)
                    }
                   
                }
            )
        }
    }

    function setCustomDate(date, fn) {
        const d = `${moment(date).format("DD-MM-YYYY")}`
        
        // fn(d)
        return d;
    }

    const getProductionPath = () => {
        let ret = ''
        if (storageMeta.machine_id) {
            ret += `/${storageMeta.machine_id}`
            if (storageMeta.shift_id) {
                ret += `/${storageMeta.shift_id}`
                if (storageMeta.order_id) {
                    ret += `/${storageMeta.order_id}`
                    if (storageMeta.process_id) {
                        ret += `/${storageMeta.process_id}`
                    }
                }
            }
        }
        return ret
    }
      
    return (
        <div className="header-wrapper">
            <Navbar bg='white' className='topbar' expand="lg">
                <Container>
                    <Navbar.Brand><img src={`${config.images_url}/images/logo.png`} style={{ height: 50}}/></Navbar.Brand>
                    <LanguageSelect></LanguageSelect>
                    <Navbar className="justify-content-end">
                        <img src={`${config.images_url}/images/user.png`} />
                        <Navbar.Text>
                            {i18n.t("Συνδεδεμένος ως")} <span className='link-cloner'>{storage.username}</span>
                            <button className="logout-btn" onClick={() => props.logout()}>{i18n.t("Αποσύνδεση")}</button>
                        </Navbar.Text>
                    </Navbar>
                </Container>
            </Navbar>
            <Navbar className='bar-machine'>
                <Container fluid className=' menubar'>
                    {storageMeta.machine_name != ''&&
                        <div className='changebutton d-flex menu-elem'>
                            <Link onClick={() => setActive(0)} className={`btn btn-success mr-1 ${getActive(0)}`} style={{ color: '#fff' }} to={`/production${getProductionPath()}`}>{i18n.t("Παραγωγή")} </Link>
                            <Link onClick={() => setActive(2)} className={`btn btn-success ${getActive(2)}`} style={{ color: '#fff' }} to="/delays">{i18n.t("Καθυστερήσεις")}</Link>
                        </div>
                    }
                    <Nav className="align-items-center flx menu-elem">
                            {
                                storageMeta.machine_id != '' && storageMeta.machine_id !='undefined' && storageMeta.machine_id !='null' &&
                                <div className="menu-elem">
                                    <span style={{ marginRight: 5}}>{i18n.t("ΚΑΤΑΣΤΑΣΗ ΜΗΧΑΝΗΣ")}:</span>
                                    <input className="machinestatus" type="checkbox" onChange = {e => changemachinestatus(e)} checked={checked} id="machinestatus"/>
                                    <label htmlFor="machinestatus"></label>
                                </div>
                            }
                    
                    </Nav>
                    <Nav className='machine-wrp align-items-center menu-elem'>
                        <span>{storageMeta.machine_name != '' ? ` ${storageMeta.machine_name}` : ''}</span>
                        <span>{storageMeta.shift_name != '' ? ` ${storageMeta.shift_name}` : ''}</span>
                        <span>{storageMeta.date != '' ? ` ${setCustomDate(storageMeta.date)}` : ''}</span>
                        <div className='d-flex menu-choices'>
                            
                            <Link className='btn btn-success' to='/machine' style={{ color: '#fff' }} >{i18n.t("Αλλαγή Μηχανής")}</Link>
                        </div>
                    </Nav>
                    
                </Container>
            </Navbar>
        </div>
    )
}

export default Header;