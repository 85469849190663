import { useEffect } from "react";
import config from '../../Config';
import i18n from "../../i18n";
import './Dashboard.css'

function Dashboard(props) {

    return (
        <div className='dashboard py-4'>
        <div className="dashboard-wrapper">
            <img src={`${config.images_url}/images/logo.png`} style={{ height: 80}}/>
                <h1>{i18n.t("Διαχειριστικό Γραμμής Παραγωγής")}</h1>
                <h4 className='description'>{i18n.t("Διαχείριση, Παραγωγή, Κατασκευή")}</h4>
            <div className="footer">
                <p>Copyright © 2022, All Rights Reserved Lean Factory , Powered by iarts, web and art design</p>
            </div>
        </div>
        </div>
    )
}

export default Dashboard;