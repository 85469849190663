import React from "react";
import { Loader as LoadingIcon } from 'react-feather';

import './Loader.css'


function Loader(props) {

	return <div className={`loading-request ${props.show ? 'show' : 'hide'}`}>
		<div className="loading-icon-wrapper"><LoadingIcon className='rotate' width='75' height='75' color='white'/></div>
	</div>
}

export default Loader;