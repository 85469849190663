import translates from './translates.json'

class I18n {
  language = 'el';
  languageChangeFns = [];
  translations = translates;
  
  setLanguage(languageCode) {
    this.language = languageCode;
    this.languageChangeFns.forEach((fn) => {
      fn();
    });
  }

  onLanguageChange(fn) {
    this.languageChangeFns.push(fn);

    return () => {
      this.languageChangeFns.splice(this.languageChangeFns.indexOf(fn), 1);
    };
  }

  t(key) {
    return this.translations[this.language][key];
  }
  onLanguageChangeUnmount(fn) {
    this.languageChangeFns.splice(this.languageChangeFns.indexOf(fn), 1);
  }

  toggle(native, english){
    if(this.language == 'en'){
      return english;
    }else{
      return native;
    }
  }
  get_name(data) {
    if(this.language == 'en'){
      return data.name_en != "" ? data.name_en : data.name;
    }else{
      return data.name;
    }
  }

  get_title(data) {
    if(this.language == 'en'){
      return data.title_en != "" ? data.title_en : data.title;
    }else{
      return data.title;
    }
  }

  get_desc(data) {
    if(this.language == 'en'){
      return data.description_en != "" ? data.description_en : data.description;
    }else{
      return data.description;
    }
  }

  get_mini_desc(data) {
    if(this.language == 'en'){
      return data.miniDescription_en != "" ? data.miniDescription_en : data.miniDescription;
    }else{
      return data.miniDescription;
    }
  }

  get_message(data) {
    if(this.language == 'en'){
      return data.en != "" ? data.en : data.el;
    }else{
      return data.el;
    }
  }

  get_attribute_category(data) {
    if(this.language == 'en'){
      return data.attribute_category_en != "" ? data.attribute_category_en : data.attribute_category;
    }else{
      return data.attribute_category;
    }
  }

  get_custom_category(data){
    if(this.language == 'en'){
      return data.custom_category_text_en != "" ? data.custom_category_text_en : data.custom_category_text;
    }else{
      return data.custom_category_text;
    }
  }

  get_teaser(data){
    if(this.language == 'en'){
      return data.teaser_en != "" ? data.teaser_en : data.teaser;
    }else{
      return data.custom_category_text;
    }
  }

  get_generic(data, field){
    if(this.language == 'en'){
      return data[`${field}_en`] != "" ? data[`${field}_en`] : data[field];
    }else{
      return data[field];
    }
  }

  greeklish(text) {
    let str = text;
    let greek   = ['α','ά','Ά','Α','β','Β','γ', 'Γ', 'δ','Δ','ε','έ','Ε','Έ','ζ','Ζ','η','ή','Η','θ','Θ','ι','ί','ϊ','ΐ','Ι','Ί', 'κ','Κ','λ','Λ','μ','Μ','ν','Ν','ξ','Ξ','ο','ό','Ο','Ό','π','Π','ρ','Ρ','σ','ς', 'Σ','τ','Τ','υ','ύ','Υ','Ύ','φ','Φ','χ','Χ','ψ','Ψ','ω','ώ','Ω','Ώ',' ',"'","'",',']; 
    let english = ['a', 'a','A','A','b','B','g','G','d','D','e','e','E','E','z','Z','i','i','I','th','Th', 'i','i','i','i','I','I','k','K','l','L','m','M','n','N','x','X','o','o','O','O','p','P' ,'r','R','s','s','S','t','T','u','u','Y','Y','f','F','ch','Ch','ps','Ps','o','o','O','O',' ',' ',' ',',']; 
    
    
    for (var i = 0; i < greek.length; i++) {
      while( str.indexOf(greek[i]) !== -1 && greek[i] != english[i]){
        str = str.replace(greek[i], english[i]);    // CONVERT GREEK CHARACTERS TO LATIN LETTERS
      }
      
    }

    return str;
  }

  get_text(text) {
    if(this.language == 'en'){
      return this.greeklish(text);
    }else{
      return text;
    }
  }

  get_date_locale() {
    return this.language == 'en' ? 'en' : 'gr'
  }
}

const i18n = new I18n();
window.i18n = i18n;

export default i18n;