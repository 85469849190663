import React, { useEffect, useState, useCallback } from "react";
import {
  Router,
  Switch,
  Route,
  useParams,
  Redirect
} from "react-router-dom";
import logo from './logo.svg';
import storage from './storage';
import './App.css';
import Header from "./components/header/Header";
import Login from "./components/login/Login"; 
import Production from "./components/production/Production"; 
import Dashboard from "./components/dashboard/Dashboard";
import Delays from "./components/delays/Delays";
import Machine from "./components/machine/Machine";
import Footer from "./components/footer/Footer";
import Messages from "./components/messages/Messages";
import 'bootstrap/dist/css/bootstrap.min.css';
import history from "./history";
import request from "./request";
import i18n from "./i18n";
import config from "./Config";
import Loader from "./components/loader/Loader";


/**
 * This is the main component that runs when react is initialized
 * @return [jsxobject]
 */
function App() {

  const [showLoading, setShowLoading] = useState(false)
  const [token, setToken] = useState(
    sessionStorage.getItem('token') !== null && sessionStorage.getItem('token') != 'null' ?
    sessionStorage.getItem('token') : null
  )
  const [showMessages, setShowMessages] = useState(true)
  
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    const local_token = storage.token && typeof storage.token != 'null' && storage.token != 'null' ? storage.token : ''
    const unsetLang = i18n.onLanguageChange(() => {
      forceUpdate()
    })
    setToken(local_token)

    
    // window.addEventListener('beforeunload', function (event) {
    //   event.preventDefault();
    //   request.get('/before_logout')
    //   return event.returnValue = "Are you sure you want to exit?";
    // });
    
    
    // window.addEventListener('unload', function (event) {
      
    //   request.post('/logout').then(response => {
    //     if (response.data.status == 200) {
    //       storage.clear()
    //       sessionStorage.clear()
    //       setToken('')
    //     }
    //     console.log(response)    
    //   });
    // });

    return () => {
      unsetLang()
    }
  }, [])
  const showHideLoading = (b) => {
    setShowLoading(b)
  }
  const onLogin = (login_token) => {
    // sessionStorage.setItem('token', login_token)
    storage.set("token", login_token)
    setToken(login_token)
    // console.log(login_token)
    
  } 

  const handleShowMessages = (e) => {
    setShowMessages(e)
  }

  const onLogout = () => {
    // sessionStorage.setItem('token', '')
    request.post('/logout')
    storage.clear()
    sessionStorage.clear()
    setToken('')
    history.push("/login")
  }

  const requireAuth = (nextState, replace, next) => {
    // console.log("on require")
    if(!token || token === '') // pseudocode - SYNCHRONOUS function (cannot be async without extra callback parameter to this function)
      history.push('/login')
  }

  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) => {
          return (token && token !== '') ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        }}
      />
    );
  }

  return (
    <div className="App">
      <Router
        basename={config.basename}
        history={history}
        >
        {(token && token !== '') &&
              
        <Header logout={ onLogout } />
        }
        <div className='body'>
          <Switch>
            <PrivateRoute exact path="/">
                <Machine showHideLoading={showHideLoading} />
            </PrivateRoute>
            <Route path="/login/:clientParam">
              <Login onLogin={onLogin} showHideLoading={showHideLoading}/>
            </Route>
            <Route path="/login">
              <Login onLogin={onLogin} showHideLoading={showHideLoading}/>
            </Route>
            <PrivateRoute path="/machine">
              <Machine showHideLoading={showHideLoading}/>
            </PrivateRoute>
            <PrivateRoute path="/production/:machineID/:shiftID/:orderID/:processID">
              <Production showHideLoading={showHideLoading}/>
            </PrivateRoute>
            <PrivateRoute path="/production/:machineID/:shiftID">
              <Production showHideLoading={showHideLoading}/>
            </PrivateRoute>
            <PrivateRoute path="/production">
              <Production showHideLoading={showHideLoading}/>
            </PrivateRoute>
            <PrivateRoute path="/delays">
              <Delays showHideLoading={showHideLoading}/>
            </PrivateRoute>
            <PrivateRoute path="/messages/:userMessageID">
              <Messages showHide={handleShowMessages} showHideLoading={showHideLoading}
              />
            </PrivateRoute>
            <PrivateRoute path="/messages">
              <Messages showHide={handleShowMessages} showHideLoading={showHideLoading}
              />
            </PrivateRoute>
            
            <Route path="/:clientParam">
              <Login onLogin={onLogin} showHideLoading={showHideLoading}/>
            </Route>
          </Switch>
        </div>
        {(token && token !== '') &&
               
          <Footer showMessageBtn={showMessages}></Footer>
        }
        <Loader show={showLoading}/>
          
        </Router>
      
    </div>
  );
}

export default App;
