import Flatpickr from "react-flatpickr";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import "flatpickr/dist/themes/material_blue.css";
import './Delays.css';
import storage from '../../storage';
import request from '../../request';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import moment from 'moment';
import DataTable from "react-data-table-component";
import React, { useMemo } from "react"; //Pass a “create” function and an array of dependencies. useMemo will only recompute the memoized value when one of the dependencies has changed. This optimization helps to avoid expensive calculations on every render.
// import FilterComponent from "./Filter";
import { Edit } from 'react-feather';
import Table from 'react-bootstrap/Table';
import history from "../../history";
import Swal from 'sweetalert2';
import i18n from "../../i18n";

function Delays(props) {
    const [delayTypeList, setDelayTypeList] = useState([]);
    const [selectedDtype, setSelectDtype] = useState();
    const [delaysList, setDelayList ] = useState([]);
    const [data, setDelays] = useState([]);
    const [addelayrow, setAddDelayrow] = useState({
        delaytype: null,
        delayqua: '',
        comments: ''
    });

    /* Format Date */
    
    function setCustomDate(date, fn) {
        const d = `${moment(date).format("YYYY-MM-DD")}`
        
        // fn(d)
        return d;
    }
    /* End of Format Date */
    
    useEffect(() => {
        if (!storage.machine_id || !storage.shift_id || !storage.date) return;
        getDelayTypelist();
        getDelayList();
    }, [])

    useEffect( () => {
        // handleCommands({})
    }, [selectedDtype])


    /* Delays List types request  */
    const getDelayTypelist = () => {
        request.get('/delaystypes').then(response => {
            if (response.data) {
                setDelayTypeList(response.data);
            }
        })
    }
    /* End of delays list types request */

    /* Delays List request */

    const getDelayList = () => {
        request.get(`/delaysdetails/${storage.machine_id}/${setCustomDate(storage.date)}/${storage.shift_id}`).then(
            response => {
                // console.log(response.data)
                const data = Object.entries(response.data).map( item => {
                    // console.log(item)
                    return (
                        item[1]
                    )
                })
                setDelays(data)
            }
        )
    }

    /* End Delay List request */

    /* Add Delay */
    function handleAddDelay (a, index, fn = (e) => {}) {
        setAddDelayrow({
            ...addelayrow,
            [index]: a
        })

        fn(a)
    }

    const addDelayreq = () => {
        if(addelayrow.delayqua > 0 && addelayrow.delaytype !=null) {
            // storage.set('delay_id', selectedDtype);
            props.showHideLoading(true)
            request.post('/addelays',
            {
                machine_id: storage.machine_id,
                delay_type: addelayrow.delaytype.value,
                date: setCustomDate(storage.date),
                shift_id: storage.shift_id,
                quantity: addelayrow.delayqua,
                comments: addelayrow.comments

            }).then (
                response => {
                    const data = Object.entries(response.data).map( item => {
                        // console.log(item)
                        return (
                            item[1]
                        )
                    })
                    if(response.status == '200') {
                        Swal.fire({
                            icon: 'success',
                            title: i18n.t('Η καταχώρηση ολοκληρώθηκε'),
                            showConfirmButton: false,
                            timer: 1500
                          })
                          setAddDelayrow({
                            delaytype: null,
                            delayqua: '',
                            comments: ''
                          })
                          setSelectDtype(null)
                    }
                    setDelays(data)
                    // console.log(response.data)
                    props.showHideLoading(false)
                }
                
            ).catch(r => {
                props.showHideLoading(false)
            })
        }
        
    }
    /*End of Add Delay */

    /* Array of delays */
    const delays = [
        {
            name:i18n.t('ΚΑΘΥΣΤΕΡΗΣΗ'),
            width: '240px',
            selector: row => row['ddescr'],
            sortable: true
        },
        {
            name:i18n.t('ΒΑΡΔΙΑ'),
            width: '130px',
            selector: row => row['shiftdescr'],
        },
        {
            name:i18n.t('ΗΜΕΡΟΜΗΝΙΑ'),
            width: '130px',
            selector: row => row['Ddate']
        },
        {
            name:i18n.t('ΧΡΟΝΟΣ (λεπτά)'),
            width: '120px',
            selector: row => row['Dtime'],
            cell: e => <div className='cell-num'>{e['Dtime']}</div>
        },
        {
            name:i18n.t('ΣΧΟΛΙΑ'),
            selector: row => row['comments']
        }
        
    ]

    
    /* End of delay arrays */
    return (
        <div className='body-details'>
            <Container className="pd0 mt-4">
                {
                (!storage.machine_id || !storage.shift_id || !storage.date) ? 
                <div><h5 className='py-2 bcolor'>{i18n.t("Δεν έχετε ορίσει τις απαραίτητες παραμέτρους")}</h5></div>
                :
                <div className="App delays-t">
                    <Form className='form-prd'>
                        <h5 className="py-2 bcolor">{i18n.t("Καταχώρηση καθυστερήσεων")}</h5>
                        <Row className='justify-content-center'>
                            <Col lg={4} md={6}>
                                <Form.Group className="mb-3 form-d" controlId="delayform">
                                    {/* <Form.Label>Επιλογή Καθυστέρησης</Form.Label> */}
                                    <Select onChange={e => handleAddDelay(e, 'delaytype', setSelectDtype)} options={delayTypeList.map(obj =>
                                        {
                                            return {
                                                value:obj.DTID,
                                                label: obj.ddescr
                                            }
                                        }
                                        )}
                                        value={selectedDtype} isSearchable={false} placeholder={i18n.t("Επιλέξτε καθυστέρηση")}>

                                    </Select>
                                </Form.Group>
                            </Col>
                            <Col lg={4} md={6}>
                                <Form.Group className="mb-3 form-d" controlId="delayform">
                                    <input type='number' name='delayqua' className='form-control' onChange={(e) => handleAddDelay(e.target.value, 'delayqua')} value={addelayrow.delayqua} min='1' placeholder={i18n.t("Διάρκεια (λεπτά)")} />
                                </Form.Group>
                            </Col>
                            <Col lg={4} md={6}>
                                <Form.Group className="mb-3 form-d" controlId="delayform">
                                    <input type='text' name='comments' className='form-control' onChange = { (e) => handleAddDelay(e.target.value, 'comments')} value={addelayrow.comments} placeholder={i18n.t("Σχόλια")}/>
                                </Form.Group>
                            </Col>
                            <div className="col-lg-4 col-md-6">
                                <button type="button" className="btn btn-success" onClick={addDelayreq}>{i18n.t("Προσθήκη")}</button>
                            </div>
                            
                        </Row>
                    </Form>
                    <DataTable
                    title={i18n.t("Καθυστερήσεις")}
                    columns={delays}
                    data={data}
                    noDataComponent={i18n.t("Δεν υπάρχουν εγγραφές")}
                    // selectableRows
                    >
                    </DataTable>
                </div>
                }
                
            </Container>
        </div>
    )

   
} 
export default Delays;