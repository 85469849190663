import i18n from "./i18n";

class Utils{

    timeSince(date) {

        var seconds = Math.floor((new Date() - new Date(date)) / 1000);
        var interval = seconds / 31536000;
      
        if (interval > 1) {
            var pad_text = Math.floor(interval) > 1 ? ` ${i18n.t("χρόνια πριν")}` : ` ${i18n.t("χρόνο πριν")}`
            return  `${i18n.t("πριν")} ` + Math.floor(interval) + pad_text;
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            var pad_text = Math.floor(interval) > 1 ? ` ${i18n.t("μήνες πριν")}` : ` ${i18n.t("μήνα πριν")}`
            return  `${i18n.t("πριν")} ` + Math.floor(interval) + pad_text;
        }
        interval = seconds / 86400;
        if (interval > 1) {
            var pad_text = Math.floor(interval) > 1 ? ` ${i18n.t("μέρες πριν")}` : ` ${i18n.t("μέρα πριν")}`
            return  `${i18n.t("πριν")} ` + Math.floor(interval) + pad_text;
        }
        interval = seconds / 3600;
        if (interval > 1) {
            var pad_text = Math.floor(interval) > 1 ? ` ${i18n.t("ώρες πριν")}` : ` ${i18n.t("ώρα πριν")}`
            return  `${i18n.t("πριν")} ` + Math.floor(interval) + pad_text;
        }
        interval = seconds / 60;
        if (interval > 1) {
            var pad_text = Math.floor(interval) > 1 ? ` ${i18n.t("λεπτά πριν")}` : ` ${i18n.t("λεπτό πριν")}`
            return  `${i18n.t("πριν")} ` + Math.floor(interval) + pad_text;
        }
        if(Math.floor(seconds) > 1)
            return `${i18n.t("πριν")} ` + Math.floor(seconds) + ` ${i18n.t("δευτερόλεπτα πριν")}`;
        else 
            return `${i18n.t("πριν")} 1 ${i18n.t("δευτερόλεπτο πριν")}`;
    }
}
const utils = new Utils();

export default utils